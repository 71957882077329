import { createIcon } from "./shared";

const paths = (
  <>
    <path
      d="M28.7507 32.0832H11.2503C9.40938 32.0832 7.91699 30.5908 7.91699 28.7498V11.2498C7.91699 9.40889 9.40937 7.9165 11.2503 7.9165H28.7507C30.5917 7.9165 32.0841 9.40889 32.0841 11.2498V28.7498C32.0841 30.5908 30.5917 32.0832 28.7507 32.0832Z"
      fill="#666666"
      stroke="#666666"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8733 15.1187V23.7975H12.0383V16.8604H11.9875L10 18.1063V16.479L12.1485 15.1187H13.8733Z"
      fill="white"
    />
    <path
      d="M15.936 23.7975V22.4754L19.0253 19.6149C19.288 19.3606 19.5084 19.1318 19.6864 18.9284C19.8672 18.725 20.0042 18.5258 20.0974 18.3309C20.1907 18.1331 20.2373 17.9198 20.2373 17.691C20.2373 17.4367 20.1794 17.2177 20.0635 17.0341C19.9477 16.8476 19.7895 16.705 19.5889 16.6061C19.3883 16.5044 19.1609 16.4535 18.9066 16.4535C18.6411 16.4535 18.4094 16.5072 18.2117 16.6146C18.0139 16.7219 17.8613 16.8759 17.754 17.0765C17.6466 17.2771 17.5929 17.5158 17.5929 17.7927H15.8512C15.8512 17.2248 15.9798 16.7318 16.2369 16.3137C16.494 15.8956 16.8542 15.5721 17.3175 15.3433C17.7808 15.1144 18.3148 15 18.9194 15C19.5409 15 20.0819 15.1102 20.5424 15.3305C21.0057 15.5481 21.3659 15.8504 21.623 16.2374C21.8801 16.6245 22.0087 17.068 22.0087 17.5681C22.0087 17.8958 21.9437 18.2193 21.8137 18.5385C21.6866 18.8577 21.4592 19.2123 21.1315 19.6022C20.8037 19.9892 20.3418 20.454 19.7457 20.9964L18.4786 22.238V22.2974H22.1231V23.7975H15.936Z"
      fill="white"
    />
    <path
      d="M26.6606 23.9162C26.0278 23.9162 25.4642 23.8074 24.9698 23.5899C24.4782 23.3695 24.0897 23.0672 23.8044 22.683C23.5219 22.296 23.3764 21.8496 23.3679 21.3439H25.2156C25.2269 21.5558 25.2961 21.7422 25.4232 21.9033C25.5532 22.0615 25.7255 22.1844 25.9402 22.2719C26.1549 22.3595 26.3965 22.4033 26.6649 22.4033C26.9446 22.4033 27.1918 22.3539 27.4065 22.255C27.6212 22.1561 27.7893 22.0191 27.9108 21.8439C28.0322 21.6688 28.093 21.4668 28.093 21.2379C28.093 21.0063 28.028 20.8014 27.898 20.6235C27.7709 20.4427 27.5873 20.3014 27.3471 20.1997C27.1098 20.098 26.8273 20.0471 26.4996 20.0471H25.6902V18.6995H26.4996C26.7765 18.6995 27.0208 18.6515 27.2327 18.5555C27.4474 18.4594 27.6141 18.3266 27.7328 18.1571C27.8514 17.9848 27.9108 17.7842 27.9108 17.5554C27.9108 17.3378 27.8585 17.1471 27.754 16.9833C27.6523 16.8166 27.5082 16.6866 27.3217 16.5934C27.1381 16.5002 26.9234 16.4535 26.6776 16.4535C26.429 16.4535 26.2015 16.4987 25.9953 16.5891C25.7891 16.6767 25.6238 16.8024 25.4995 16.9663C25.3752 17.1302 25.3088 17.3223 25.3003 17.5426H23.5417C23.5501 17.0426 23.6928 16.6019 23.9697 16.2205C24.2465 15.8391 24.6195 15.541 25.0884 15.3263C25.5602 15.1088 26.0928 15 26.6861 15C27.285 15 27.8091 15.1088 28.2583 15.3263C28.7075 15.5438 29.0564 15.8377 29.305 16.2078C29.5564 16.575 29.6807 16.9875 29.6779 17.4452C29.6807 17.9311 29.5296 18.3365 29.2245 18.6614C28.9222 18.9863 28.5281 19.1925 28.0421 19.2801V19.3479C28.6806 19.4298 29.1665 19.6516 29.4999 20.0132C29.8361 20.372 30.0028 20.8212 30 21.3608C30.0028 21.8552 29.8601 22.2945 29.5719 22.6788C29.2866 23.063 28.8925 23.3653 28.3896 23.5856C27.8867 23.806 27.3104 23.9162 26.6606 23.9162Z"
      fill="white"
    />
  </>
);

const ScorecardIcon = createIcon(paths, "ScorecardIcon", "0 0 40 40");
export default ScorecardIcon;
